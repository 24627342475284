import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesUnionOverview: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Union Raid overview</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_unionraid.png"
            alt="Union Raid"
          />
        </div>
        <div className="page-details">
          <h1>Union Raid overview</h1>
          <h2>Overview for the Union Raid system.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Intro" />
        <p>
          Union Raid is available for guilds that reached at least level 3 and
          each Raid Season will last for 7 days. For now we don't know how often
          the mode will become available, but the first season will start on the
          27th January 2023.
        </p>
        <p>
          Overall, if you played Princess Connect, you will feel at home,
          because the Union Raid is a direct copy of the mode. For some, this
          will be a blessing, but for others, a curse. While casual players will
          be able to smack the bosses and get some rewards, for those that want
          to reach the highest ranks things will look much complicated.
        </p>
        <SectionHeader title="Gameplay loop" />
        <StaticImage
          src="../../../images/nikke/generic/union_guide_1.jpg"
          alt="Union Raid"
        />
        <p>
          As mentioned earlier, to access the Union Raid you need to be a part
          of a Union that's at least level 3. Then the Union Raid tile visible
          on the Union screen will become available to you - but keep in mind
          that you won't be able to enter it till the season starts.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/union_guide_2.jpg"
          alt="Union Raid"
        />
        <p>
          After entering the Union Raid you will be welcomed by the screen above
          that shows the current round, the current boss and how many attempts
          you have left for this day. All of that is visible on the image above.
        </p>
        <p>Which bosses you fight depends on the season.</p>
        <p>
          Once your guild defeats all 5 bosses within a round, you will advance
          to the next one and again fight Obelisk first. Though keep in mind
          that the difficulty of the battles will also increase as with each
          stage, the bosses gain more stats.
        </p>
        <p>
          We created a dedicated page for each boss where you can find its stats
          (per stage) and also videos showing sample battles (as the bosses
          already appeared in the story).
        </p>
        <StaticImage
          src="../../../images/nikke/generic/union_guide_3.jpg"
          alt="Union Raid"
        />
        <p>
          You only get 3 chances a day to attack the bosses and you have to{' '}
          <strong>build 3 different teams to do that</strong> - if you use
          Modernia in the first battle, you won't be able to use her in the
          remaining ones. This will force you be smart about the characters you
          use against each boss, because for sure some will shine in different
          teams and against a different boss.
        </p>
        <p>
          Also, since the difficulty of the bosses will drastically increase
          with each round, the weaker members of the Union should attack in the
          first rounds, so the strongest members can deal as much damage
          possible against the stronger bosses.
        </p>
        <SectionHeader title="Rewards" />
        <p>
          The main reward in Union Raids are <strong>Union Chips</strong> that
          you can spend in the Union Shop to buy spare bodies for characters
          obtainable thought Liberation mode and Batteries used to upgrade your
          Cubes.
        </p>
        <p>
          You can obtain Union Chips for killing one of the bosses and based on
          the rank your Union achieved after the season.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesUnionOverview;

export const Head: React.FC = () => (
  <Seo
    title="Union Raid overview | NIKKE | Prydwen Institute"
    description="Overview for the Union Raid system."
  />
);
